import { GSBB_USER } from "../../../excalidraw-app/app_constants";
import { getClientColor } from "../../clients";
import { UIAppState } from "../../types";
import { Avatar } from "../Avatar";

const User = ({ appState }: { appState: UIAppState }) => {
  const background = getClientColor(GSBB_USER.nickName);
  return (
    <Avatar
      color={background}
      onClick={() => {}}
      name={GSBB_USER.nickName || ""}
      src={GSBB_USER.avatarBlob}
    />
  );
};

export default User;
User.displayName = "User";
