import { isLoggedInGSBB } from "../app_constants";

export const PlusAppLink = () => {
  if (!isLoggedInGSBB) {
    return null;
  }
  return (
    <a
      href={`${import.meta.env.VITE_APP_PLUS_URL}/`}
      target="_blank"
      rel="noreferrer"
      className="plus-button"
    >
      去登录云端
    </a>
  );
};
