import React, { useEffect, useState } from "react";

import { Theme } from "../element/types";
import { defaultLang, Language, languages, setLanguage } from "../i18n";
import { LoadingMessage } from "./LoadingMessage";

interface Props {
  langCode: Language["code"];
  children: React.ReactElement;
  theme?: Theme;
}

export const InitializeApp = (props: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const updateLang = async () => {
        await setLanguage(currentLang);
        setLoading(false);
      };
      const currentLang =
        languages.find((lang) => lang.code === props.langCode) || defaultLang;
      updateLang();
    } catch (error) {
      console.error("updateLang error", error);
    }
  }, [props.langCode]);

  return loading ? <LoadingMessage theme={props.theme} /> : props.children;
};
