/**
 * 保存到服务端汇总
 */
import { editModeSyncToGSBB } from "../../excalidraw-app/components/ExportToGSBB";
import { KEYS } from "../keys";
import { getShortcutKey } from "../utils";
import { register } from "./register";

/**
 * 同步到服务器
 */
export const actionSyncSceneToServer = register({
  name: "syncSceneToServer",
  trackEvent: { category: "sync" },
  perform: async (elements, appState, value, app) => {
    try {
      // 上传 场景文件&附件文件
      await editModeSyncToGSBB(elements, appState, app.files);

      return {
        commitToHistory: false,
        appState: {
          ...appState,
          openDialog: null,
          toast: { message: "已保存" },
        },
      };
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  contextItemLabel: "labels.syncSceneToServer",
  keyPriority: 40,
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.code === KEYS.S,
  PanelComponent: ({ updateData }) => (
    <button
      type="button"
      className="zIndexButton"
      onClick={() => updateData(null)}
      title={`保存到云端 — ${getShortcutKey("Ctrl+S")}`}
    ></button>
  ),
});
