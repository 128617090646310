import { getCookieToJson } from "../../src/utils";
import { COOKIES } from "../app_constants";

export const userNickName = () => {
  const user: any = getCookieToJson(COOKIES.AUTH_USER_COOKIE);
  return user ? user.nickName : "";
};

export const userAvatar = () => {
  const user: any = getCookieToJson(COOKIES.AUTH_USER_COOKIE);
  return user ? user.avatar : "";
};
