import { MIME_TYPES } from "../../src/constants";
import { decompressData } from "../../src/data/encode";
import { FileId } from "../../src/element/types";
import { BinaryFileData, BinaryFileMetadata, DataURL } from "../../src/types";

const UPSTORAGE_POST = import.meta.env.VITE_UPSTORAGE_POST_URL;
const UPSTORAGE_GET = import.meta.env.VITE_UPSTORAGE_GET_URL;

export const saveFilesToUpStorage = async ({
  prefix,
  files,
}: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<string, string>();

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        // 创建 FormData 对象
        const formData = new FormData();
        formData.append("path", `${prefix}/${id}`);
        // 添加 Blob 到 FormData
        formData.append(
          "blob",
          new Blob([buffer], {
            type: MIME_TYPES.binary,
          }),
        );

        const response = await fetch(UPSTORAGE_POST, {
          method: "POST",
          body: formData,
        });
        const json = await response.json();
        if (!json.success) {
          console.log("附件未能上传成功,file=", id.toString());
        } else {
          console.log("附件上传成功,file=", id.toString());
        }
        savedFiles.set(id.toString(), json.id);
      } catch (error: any) {
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

export const loadFilesFromUpStorageBase = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        debugger;
        const url = `${UPSTORAGE_GET}?f=${prefix},${id}`;
        const response = await fetch(`${url}&alt=media`, {
          method: "GET",
          credentials: "include", // 确保携带Cookie
        });
        if (response.status < 400) {
          const arrayBuffer = await response.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};
