import { nanoid } from "nanoid";
import React from "react";
import { trackEvent } from "../../src/analytics";
import { Card } from "../../src/components/Card";
import { GSBBLogo } from "../../src/components/GSBBLogo";
import { ToolButton } from "../../src/components/ToolButton";
import { MIME_TYPES } from "../../src/constants";
import { encryptData, generateEncryptionKey } from "../../src/data/encryption";
import { serializeAsJSON } from "../../src/data/json";
import { isInitializedImageElement } from "../../src/element/typeChecks";
import { FileId, NonDeletedExcalidrawElement } from "../../src/element/types";
import { useI18n } from "../../src/i18n";
import { exportToBlob } from "../../src/packages/utils";
import { AppState, BinaryFileData, BinaryFiles } from "../../src/types";
import { getFrame } from "../../src/utils";
import { FILE_UPLOAD_MAX_BYTES } from "../app_constants";
import { encodeFilesForUpload } from "../data/FileManager";
import { saveFilesToUpStorage } from "../data/UpStorage";

const BACKEND_POST = import.meta.env.VITE_APP_BACKEND_V2_POST_URL;
const SYNC_SERVER_POST = import.meta.env.VITE_APP_SYNC_SERVER_POST_URL;

// TODO：将画布数据导出Blob
// const [blobUrl, setBlobUrl] = useState<string>("");
const handleExportToBlob = async (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
) => {
  const blob = await exportToBlob({
    elements: elements,
    mimeType: "image/png",
    appState: appState,
    files: files,
  });
  // setBlobUrl(window.URL.createObjectURL(blob));
  return blob;
};

/**
 * 导出到GSBB - 主动保存
 * @param elements
 * @param appState
 * @param files
 */
export const exportToGSBB = async (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
) => {
  const slug = `${nanoid(12)}`;

  const encryptionKey = (await generateEncryptionKey())!;
  const encryptedData = await encryptData(
    encryptionKey,
    serializeAsJSON(elements, appState, files, "database"),
  );

  const blob = new Blob(
    [encryptedData.iv, new Uint8Array(encryptedData.encryptedBuffer)],
    {
      type: MIME_TYPES.binary,
    },
  );

  debugger;
  const imgBlob = await handleExportToBlob(elements, appState, files);
  // 上传到GSBB后端
  // 上传主数据文件
  // 创建 FormData 对象
  const formData = new FormData();
  // 添加 Blob 到 FormData
  formData.append("data", blob);
  formData.append("encryptionKey", encryptionKey);
  // 添加其他参数
  formData.append("slug", slug);
  formData.append("name", appState.name ?? "");
  formData.append("path", "scenes");
  formData.append("img", imgBlob ?? new Blob());

  // 优先上传附件：
  // 上传附件
  const filesMap = new Map<FileId, BinaryFileData>();
  for (const element of elements) {
    if (isInitializedImageElement(element) && files[element.fileId]) {
      filesMap.set(element.fileId, files[element.fileId]);
    }
  }

  let attachs: Array<{ key: string; value: string }> = []; // 附件ID、附件存储ID
  if (filesMap.size) {
    const filesToUpload = await encodeFilesForUpload({
      files: filesMap,
      encryptionKey,
      maxBytes: FILE_UPLOAD_MAX_BYTES,
    });

    const { savedFiles, erroredFiles } = await saveFilesToUpStorage({
      prefix: `files/scenes`,
      files: filesToUpload,
    });
    // TODO: 附件上传失败,处理
    if (erroredFiles && erroredFiles.size > 0) {
      alert("附件上传失败");
    }

    // TODO:附件上传成功,处理
    if (savedFiles && savedFiles.size > 0) {
      savedFiles.forEach((value, key) => {
        attachs.push({ key, value });
      });
    }
  }

  if (attachs && attachs.length > 0) {
    formData.append("attachs", JSON.stringify(attachs));
  }

  const response = await fetch(BACKEND_POST, {
    method: "POST",
    body: formData,
  });
  const json = await response.json();
  if (json.success) {
    let url = `${import.meta.env.VITE_APP_PLUS_URL}/import?gsbb=${json.id}`;
    window.open(url);
  }
};
/**
 * 编辑模式下，保存到GSBB - 主动保存
 * @param elements
 * @param appState
 * @param files
 */
export const editModeSyncToGSBB = async (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: Partial<AppState>,
  files: BinaryFiles,
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  if (!id) {
    alert("缺少关键参数，请核查");
    return;
  }

  const slug = `${nanoid(12)}`; // 版本

  const encryptionKey = appState.decryptionKey!;
  const encryptedData = await encryptData(
    encryptionKey,
    serializeAsJSON(elements, appState, files, "database"),
  );

  const blob = new Blob(
    [encryptedData.iv, new Uint8Array(encryptedData.encryptedBuffer)],
    {
      type: MIME_TYPES.binary,
    },
  );

  const imgBlob = await handleExportToBlob(elements, appState, files);
  // 上传到GSBB后端
  // 上传主数据文件/场景文件
  // 创建 FormData 对象
  const formData = new FormData();
  // 添加 Blob 到 FormData
  formData.append("data", blob);
  // formData.append("encryptionKey", encryptionKey);
  // 添加其他参数
  formData.append("id", id!);
  formData.append("name", appState.name!);
  formData.append("slug", slug);
  formData.append("path", "scenes");
  formData.append("img", imgBlob ?? new Blob());

  // 场景：附件
  // 上传附件
  const filesMap = new Map<FileId, BinaryFileData>();
  for (const element of elements) {
    if (isInitializedImageElement(element) && files[element.fileId]) {
      filesMap.set(element.fileId, files[element.fileId]);
    }
  }
  let attachs = "";
  if (filesMap.size) {
    const filesToUpload = await encodeFilesForUpload({
      files: filesMap,
      encryptionKey,
      maxBytes: FILE_UPLOAD_MAX_BYTES,
    });

    const { savedFiles, erroredFiles } = await saveFilesToUpStorage({
      prefix: `files/scenes/${id!}`,
      files: filesToUpload,
    });
    // TODO: 附件上传失败,处理
    if (erroredFiles && erroredFiles.size > 0) {
      alert("附件上传失败");
    }
    // TODO:附件上传成功,处理
    if (savedFiles && savedFiles.size > 0) {
      savedFiles.forEach((value, key) => {
        attachs += `${value},`;
      });
    }
  }

  // 场景：主数据
  const response = await fetch(SYNC_SERVER_POST, {
    method: "POST",
    credentials: "include", // 确保携带Cookie
    body: formData,
  });
  const json = await response.json();
  if (json.success) {
    console.log("保存成功");
  } else {
    alert(`保存失败：${json.message}`);
  }
};

export const ExportToGSBB: React.FC<{
  elements: readonly NonDeletedExcalidrawElement[];
  appState: Partial<AppState>;
  files: BinaryFiles;
  onError: (error: Error) => void;
  onSuccess: () => void;
}> = ({ elements, appState, files, onError, onSuccess }) => {
  const { t } = useI18n();

  return (
    <Card color="primary">
      <div className="Card-icon">
        <GSBBLogo
          style={{
            [`--color-logo-icon` as any]: "#fff",
            width: "2.8rem",
            height: "2.8rem",
          }}
        />
      </div>
      <h2>构思白板</h2>
      <div className="Card-details">
        {t("exportDialog.excalidrawplus_description")}
      </div>
      <ToolButton
        className="Card-button"
        type="button"
        title={t("exportDialog.excalidrawplus_button")}
        aria-label={t("exportDialog.excalidrawplus_button")}
        showAriaLabel={true}
        onClick={async () => {
          try {
            trackEvent("export", "gsbb", `ui (${getFrame()})`);

            debugger;
            await exportToGSBB(elements, appState, files);
            onSuccess();
          } catch (error: any) {
            console.error(error);
            if (error.name !== "AbortError") {
              onError(new Error(t("exportDialog.excalidrawplus_exportError")));
            }
          }
        }}
      />
    </Card>
  );
};
