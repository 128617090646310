import React from "react";
import { Footer } from "../../src/packages/excalidraw/index";
import { isLoggedInGSBB } from "../app_constants";
import { PlusAppLink } from "./PlusAppLink";

export const AppFooter = React.memo(() => {
  return (
    <Footer>
      <div
        style={{
          display: "flex",
          gap: ".5rem",
          alignItems: "center",
        }}
      >
        {!isLoggedInGSBB ? <PlusAppLink /> : null}
      </div>
    </Footer>
  );
});
