/**
 * 手动同步服务
 */

import { actionSyncSceneToServer } from "../../src/actions";
import { useExcalidrawActionManager } from "../../src/components/App";
import { Button } from "../../src/components/Button";
import { save } from "../../src/components/icons";
import { Tooltip } from "../../src/components/Tooltip";
import { ExcalidrawElement } from "../../src/element/types";
import { BinaryFiles, UIAppState } from "../../src/types";

interface PublicProps {
  appState: UIAppState;
  elements: readonly ExcalidrawElement[];
  files: BinaryFiles;
}
type Props = PublicProps & { modalIsShown: boolean };

const ManualSyncServer: React.FC<PublicProps> = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");

  const actionManager = useExcalidrawActionManager();

  // 已登录，保存到云端
  // 1、编辑模式下，保存到云端
  // 2、新增模式下，保存到云端
  // const syncData = async () => {
  //   try {
  //     // 上传 场景文件&附件文件
  //     await editModeSyncToGSBB(props.elements, props.appState, props.files);

  //     // props.appState.toast && props.appState.toast({ message: "保存成功" });
  //   } catch (error) {
  //     props.appState.errorMessage = "请注意：保存失败，请核查或稍候重试";
  //   }
  // };

  return (
    <>
      {id ? (
        <Tooltip label="保存到云端" long={false}>
          <Button
            type="button"
            onSelect={() =>
              actionManager.executeAction(actionSyncSceneToServer)
            }
            style={{ position: "relative" }}
            className="save-button"
          >
            {save}
          </Button>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export default ManualSyncServer;
